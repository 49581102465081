import './App.css';
import { Navbar } from 'react-bootstrap';
import {Stack}  from './Stack';

function App() {
  return (
    <div className="App">
      <Navbar bg="dark" variant="dark">
        <Navbar.Brand href="#home">
          Stackplan
        </Navbar.Brand>
      </Navbar>
      <main>
        <Stack/>
      </main>
    </div>
  );
}

export default App;
