import arrowBarDown from './images/arrow-bar-down.svg';
import complete from './images/complete.svg';
import { EditDataModal } from './EditDataModal';
import { SplitDataModal } from './SplitDataModal';
import styled from 'styled-components'

const TaskContainer = styled.div`
    margin-left:10px;
    margin-right:10px;
    margin-top:1px;
    margin-bottom:1px;
    width: 500px;
`;

function TaskInStack(props) {
    const task = props.task;
    const taskDescription = task.description;
    const tags = task.tags;
    const series = task.seriesCount;
    const taskId = task==null?null:task.taskId;
    const deadline = task.deadline;
    const moveToBottomCy = "move-to-bottom";
    const completeTaskFunc = () => {
        props.completeTask(taskId);
    }
    const moveCurrentToBottomFunc = () => {
        props.moveCurrentToBottom(taskId);
    }

    const editTaskToStack = (task) => {
        props.editTask(task);
    }

    return <TaskContainer>
    <div data-cy="task" className="alert alert-info" id={taskId}>
        <p className="d-flex justify-content-center">
        {taskDescription} 
        {series != null && series > 0 ? " [" + series + "]":""}
        {' '}
        {deadline}
        </p>
    <p className="d-flex justify-content-end">
    {tags}
    </p>
    <button type="button" className="btn btn-primary" onClick={moveCurrentToBottomFunc} data-cy={moveToBottomCy}>
        <img src={arrowBarDown} alt="move to bottom"/>
    </button>
    <button type="button" className="btn btn-primary" onClick={completeTaskFunc} data-cy={"complete"}>
        <img src={complete} alt="complete"/>
    </button>
    <EditDataModal editTaskToStack={editTaskToStack} task={task}/>
    <SplitDataModal splitTaskToStack={props.splitTaskToStack} task={task}/>
    <div className="d-flex justify-content-center">
    <div className="btn-group" role="group" aria-label="Basic example">
    </div>
    </div>
    </div></TaskContainer>;
}

export {TaskInStack};