import arrowupSvg from './images/arrow-up.svg';
import swapSvg from './images/swap.svg';
import refresh from './images/refresh.svg';
import cloud from './images/cloud.svg';
import logout from './images/logout.svg';
import { AddDataModal} from './AddDataModal';
import styled from 'styled-components'
import ascending from  './images/ascending-sort.svg';

const Buttons = styled.div`
display: flex;
flex-direction: row;
flex-wrap: nowrap;
align-items: stretch;
`;

const logoutClick = () => {
    window.location.reload();
}

function StackButtons(props) {
    return <Buttons>
        <button type="button" className="btn btn-primary w-100" data-cy="pop-to-top-btn" onClick={props.moveLastToTop}>
            <img src={arrowupSvg} alt={"moveToTop"} />
        </button>
        <button type="button" className="btn btn-primary w-100" data-cy="shuffle-btn" onClick={props.shuffleTasks}>
            <img src={swapSvg} alt={"shuffle"} />
        </button>
        <button type="button" className="btn btn-primary w-100" data-cy="sort_by_completion_time" onClick={props.sortByCompletionTime}>
            <img src={ascending} alt={"sort-ascending"} />
        </button>
        <button type="button" className="btn btn-primary w-100" data-cy="refresh-btn" onClick={props.refresh}>
            <img src={refresh} alt={"refresh"} />
        </button>
        <button type="button" className="btn btn-primary w-100" disabled={!props.changed} data-cy="save-data-btn" onClick={props.upload}>
            <img src={cloud} alt={"upload"} />
        </button>
        <button type="button" className="btn btn-warning w-100" data-cy="logout-btn" onClick={logoutClick}>
            <img src={logout} width="16px" height="16px" alt={"logout"} />
        </button>
        <AddDataModal addTaskToStack={props.addTaskToStack} />
        <p className="w-100" data-cy="timeleft">time left:<br /> {props.timeleft}</p>
        <p className="w-100" data-cy="timespent">time spent:<br /> {props.timespent}h</p>

    </Buttons>
}

export { StackButtons};