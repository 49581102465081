import { Card, FormControl, Alert, InputGroup } from 'react-bootstrap';
import { TaskInStack} from './TaskInStack';
import {StackButtons} from './StackButtons';
import {LoginModal} from './LoginModal';
import { React, useState, Fragment } from "react";
import styled from 'styled-components';
import { Beforeunload } from 'react-beforeunload';
import { clone, shuffle } from './arrays';
import { recalculateTasks, adjustTask } from './tasks';

const CenteredAlert = styled(Alert)`
  position: fixed; 
  top: 50%;
  left: 50%;
`;

const BlockedFilterContainer = styled.div`
 display:flex;
 flex-direction: row;
 min-width: 100px;
`;

const FilterAndBlockedContainer = styled.div`
  display:flex;
  flex-direction: row;
  margin:5px;
`;

const Queue = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px;
  justify-content: center;
`;

const QueueContainer = styled.div`
display: flex;
flex-direction: row;
margin: 5px;
justify-content: center;
`;

const asInt = (count) => {
  if (count == null) {
    return 0;
  }
  return parseInt(count);
}

function Stack() {
  const baseUrl = "https://thelaboflieven.info/sess/";
  const [filter, setFilter] = useState("");
  const [tasks, setTasks] = useState([]);
  const [loggedIn, setLoggedIn] = useState(false);
  const [loginToken, setLoginToken] = useState("");
  const [title, setTitle] = useState("");
  const [showSaved, setShowSaved] = useState(false);
  const [showItCrashed, setShowCrashed] = useState(false);
  const [crashReason, setCrashReason] = useState("");
  const [changedSinceSave, setChangedSinceSave] = useState(false);
  const [timeleft, setTimeleft] = useState("0h");
  const [timespent, setTimespent] = useState("0");
  const [blockedFilter, setBlockedFilter] = useState(false);

  const setLogin = (token) => {
    setLoginToken(token);
    download(token);
  }

  const storeTasks = (adjusted_tasks) => {
    recalculateTasks(adjusted_tasks, setTimeleft, filter);
    setTasks(clone(adjusted_tasks));
    setChangedSinceSave(true);
  }

  const moveLastToTop = () => {
    let the_current_tasks = tasks;
    const the_last_element = the_current_tasks.pop();
    the_current_tasks.unshift(the_last_element);
    storeTasks(the_current_tasks);
  }

  const editTask = (task) => {
    const adjusted = adjustTask(tasks, task);
    storeTasks(adjusted);
  }

  const moveCurrentToBottom = (taskId) => {
    storeTasks([...tasks.filter(t => t.taskId !== taskId), ...tasks.filter(t => t.taskId === taskId)]);
  }

  const shuffleTasks = () => {
    let shuffledTasks = shuffle(clone(tasks));
    storeTasks(shuffledTasks);
  }


  const sortByCompletionTime = () => {
    let shuffledTasks = clone(tasks).sort((a, b) => asInt(b.seriesCount) - asInt(a.seriesCount));
    storeTasks(shuffledTasks);
  }

  const changeFilter = (event) => {
    setFilter(event.target.value);
    recalculateTasks(tasks, setTimeleft, event.target.value);
  }

  const addTaskToStack = (task) => {
    let currentTasks = [...tasks, task];
    storeTasks(currentTasks);
  }

  const completeTask = (taskId) => {
    setTimespent(timespent + tasks.filter(t => t.taskId === taskId).map(t => t.seriesCount == 0 ? 1 : parseInt(t.seriesCount))[0]);
    storeTasks([...tasks.filter(t => t.taskId !== taskId)]);
  }

  const splitTaskToStack = (taskId, tasksToAdd) => {
    let adjusted = [...tasks.filter(t => t.taskId !== taskId)];
    let currentTasks = [...adjusted, ...tasksToAdd];
    storeTasks(currentTasks);
  }

  const changeBlockedFilter = (event) => {
    setBlockedFilter(event.target.checked);
  }

  const upload = () => {
    const Http = new XMLHttpRequest();
    const url = baseUrl + '/upload.php';
    const tasksObject = {
      title: title,
      timeleft: timeleft,
      tasks: tasks
    };
    Http.open("POST", url);
    Http.setRequestHeader('token', loginToken);
    Http.send(JSON.stringify(tasksObject));

    Http.onreadystatechange = (e) => {
      if (e.target.readyState === 4) {
        if (e.target.status === 200) {
          setShowSaved(true);
          window.setTimeout(function () { setShowSaved(false); }, 3000);
          setChangedSinceSave(false);
        }
        if (e.target.status > 200) {
          setCrashReason(e.target.responseText);
          setShowCrashed(true);
          window.setTimeout(function () { setShowCrashed(false); }, 3000);
        }

      }
    }

  }

  const download = (loginToken) => {
    const Http = new XMLHttpRequest();
    const url = baseUrl + '/download.php';
    Http.open("GET", url);
    Http.setRequestHeader('token', loginToken);
    Http.send();

    Http.onreadystatechange = (e) => {
      if (e.target.readyState === 4 && e.target.status === 200) {
        let tasksObjects = JSON.parse(Http.responseText.trim());
        let localTasks = Array.isArray(tasksObjects) ? tasksObjects : tasksObjects.tasks;
        let localTitle = Array.isArray(tasksObjects) ? "" : tasksObjects.title;
        let localTimeleft = Array.isArray(tasksObjects) ? "" : tasksObjects.timeleft;

        storeTasks(localTasks);
        setTitle(localTitle);
        setTimespent(localTimeleft  == undefined ? 0: parseInt(localTimeleft));
        document.title = localTitle;
        setLoggedIn(true);
        setChangedSinceSave(false);
      }
    }
  }

  const refresh = () => {
    download(loginToken);
  }

  const changeChangeTitle = (event) => {
    document.title = event.target.value;
    setTitle(event.target.value);
  }
  return <Fragment>
    {!loggedIn && <LoginModal register={setLogin}></LoginModal>}
    {loggedIn &&
      <Fragment>
        {changedSinceSave && <Beforeunload onBeforeunload={() => 'You’ll lose your data!'} />}
        <FormControl style={{ textAlign: "center" }} data-cy="stack-title"
          required
          placeholder="Stack title"
          aria-label="stack title"
          value={title} onChange={changeChangeTitle}></FormControl>
        <FilterAndBlockedContainer>
          <FormControl style={{ textAlign: "center" }} data-cy="stack-filter"
            required
            placeholder="filter"
            aria-label="filter"
            value={filter} onChange={changeFilter}></FormControl>
          <BlockedFilterContainer>
            <InputGroup>
              <InputGroup.Text id="blocked">Blocked</InputGroup.Text>
            </InputGroup>
            <FormControl style={{ textAlign: "center" }} 
              type="checkbox"
              placeholder="blocked"
              aria-label="blocked"
              checked={blockedFilter} data-cy="blocked-filter" onChange={changeBlockedFilter}></FormControl>
          </BlockedFilterContainer>
        </FilterAndBlockedContainer>
        <QueueContainer>
        <Queue data-cy="queue" id="queue">
          {
            (filter === "") ?
              tasks.filter(t => blockedFilter?!t.blocked:true).map(task => <TaskInStack splitTaskToStack={splitTaskToStack} key={task.taskId+task.tags} task={task} editTask={editTask} completeTask={completeTask} moveCurrentToBottom={moveCurrentToBottom} />)
              : tasks.filter(t => blockedFilter?!t.blocked:true && (t.tags.includes(filter) || t.description.includes(filter))).map(task => <TaskInStack key={task.taskId+task.tags} task={task} editTask={editTask} completeTask={completeTask} moveCurrentToBottom={moveCurrentToBottom} />)
          }
        </Queue>
        </QueueContainer>
        <StackButtons changed={changedSinceSave} timespent={timespent} sortByCompletionTime={sortByCompletionTime} addTaskToStack={addTaskToStack} moveLastToTop={moveLastToTop} shuffleTasks={shuffleTasks} upload={upload} download={download} refresh={refresh} timeleft={timeleft} />
      </Fragment>
    }
    {showSaved && <CenteredAlert data-cy={"alert-save-success"} variant={"success"}>Saved</CenteredAlert>}
    {showItCrashed && <CenteredAlert data-cy={"error-panel"} variant={"danger"}>Failed! {crashReason}</CenteredAlert>}

  </Fragment>;
}

export  { Stack };