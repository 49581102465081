import { React, useState, Fragment } from "react";
import { Modal, InputGroup, FormControl, Form } from "react-bootstrap";
import split from './images/split.svg';


function SplitDataModal(props) {
    const [isOpen, setIsOpen] = useState(false);
    const [description, setDescription] = useState("");
    const [tags, setTags] = useState("");
    const [subtasks, setSubTasks] = useState("");

    const handleShowDialog = () => {
        setDescription(props.task.description);
        setTags(props.task.tags);
        setIsOpen(!isOpen);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    const createSubTasks = () => {
        return subtasks.split("\n").filter(l => l.length > 0).map(s => {
            return {
                taskId: s,
                description: s, 
                tags: tags + " "+ description,
                seriesCount : null,
                deadline: null    
            }
        })
    };


    const handleChangeSubtasks = (event) => {
        setSubTasks(event.target.value);
    }

    const editTaskToStackFromHere = () => {
        props.splitTaskToStack(
            props.task.taskId,
            createSubTasks()    
        );
        handleClose();
    }


    return <Fragment>
        <button type="button" className="btn btn-primary" onClick={handleShowDialog} data-cy={"split"}>
            <img src={split} alt="split"/>
        </button>
        <Modal animation={false} show={isOpen} onHide={handleClose} data-cy="add-data-modal" id="addData" tabIndex="-1">
            <Modal.Header closeButton>
                <Modal.Title>Split task</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                <InputGroup className="mb-3">
                    <InputGroup>
                        <InputGroup.Text id="descript">Original Description</InputGroup.Text>
                    </InputGroup>
                    <FormControl data-cy="description-text"
                        required
                        readOnly
                        placeholder="Description"
                        aria-label="Description"
                        aria-describedby="descript" value={description}
                    />
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroup>
                        <InputGroup.Text id="tags">Tags</InputGroup.Text>
                    </InputGroup>
                    <FormControl
                        readOnly
                        placeholder="Tags"
                        aria-label="Tags"
                        aria-describedby="tags"
                        value={tags + " " + description}
                    />
                </InputGroup>

                <InputGroup className="mb-3">
                    <InputGroup>
                        <InputGroup.Text id="subtasks">Different Tasks</InputGroup.Text>
                    </InputGroup>
                    <FormControl
                        data-cy="subtasks-text"
                        as="textarea"
                        placeholder="tasks"
                        aria-label="tasks"
                        aria-describedby="tasks"
                        value={subtasks}
                        onChange={handleChangeSubtasks}
                    />
                </InputGroup>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <input type="button" className="btn btn-success" data-cy="split-task-btn" onClick={editTaskToStackFromHere} value="Split task" />
                <button type="button" className="btn btn-secondary" data-cy="close-add-task-btn" onClick={handleShowDialog}>Close</button>
            </Modal.Footer>
        </Modal>
    </Fragment>;
}

export { SplitDataModal};