import React, { useState, Fragment, useRef } from "react";
import add from './images/add.svg';
import { Modal, InputGroup, FormControl, Form } from "react-bootstrap";
import { uuidv4 } from "./uuid";

function AddDataModal(props) {
    const buttonReference = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [details, setDetails] = useState("");
    const [description, setDescription] = useState("");
    const [tags, setTags] = useState("");
    const [seriesCount, setSeriesCount] = useState(0);
    const [deadline, setDeadline] = useState("");
    const [blocked, setBlocked] = useState(false);

    const handleShowDialog = () => {
        setDescription("");
        setTags("");
        setSeriesCount(0);
        setDeadline("");
        setDetails("");
        setBlocked(false);
        setIsOpen(!isOpen);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    const handleChangeDescription = (event) => {
        setDescription(event.target.value);
    }

    const handleChangeTags = (event) => {
        setTags(event.target.value);
    }

    const handleChangeBlocked = (event) => {
        setBlocked(event.target.checked);
    }

    const handleChangeSeriesCount = (event) => {
        setSeriesCount(event.target.value);
    }

    const handleChangeDeadline = (event) => {
        setDeadline(event.target.value);
    }

    const handleChangeDetails = (event) => {
        setDetails(event.target.value);
    }

    const addTaskToStackFromHere = () => {
        if (description.length === 0) {
            return;
        }
        props.addTaskToStack({
            taskId: uuidv4(),
            description: description,
            tags: tags,
            seriesCount: seriesCount,
            deadline: deadline,
            blocked: blocked, 
            details: details
        });
        handleClose();
    }


    return <Fragment>
        <button ref={buttonReference} type="button" className="btn btn-success btn-lg btn-block" onClick={handleShowDialog} data-cy="add-data-btn" data-target="#addData">
            <img src={add} alt={"add"} />
        </button>
        <Modal animation={false} show={isOpen} onHide={handleClose} data-cy="add-data-modal" id="addData" tabIndex="-1">
            <Modal.Header closeButton>
                <Modal.Title>Add task</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <InputGroup className="mb-3">
                        <InputGroup>
                            <InputGroup.Text id="descript">Description</InputGroup.Text>
                        </InputGroup>
                        <FormControl data-cy="description-text"
                            required
                            placeholder="Description"
                            aria-label="Description"
                            aria-describedby="descript" value={description} onChange={handleChangeDescription}
                        />
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup>
                            <InputGroup.Text id="details">Details</InputGroup.Text>
                        </InputGroup>
                        <Form.Control as="textarea" data-cy="details" rows={4}
                            type="textarea"
                            placeholder="details"
                            aria-label="details"
                            aria-describedby="details" value={details} onChange={handleChangeDetails}
                        />
                    </InputGroup>

                    <InputGroup className="mb-3">
                        <InputGroup>
                            <InputGroup.Text id="blocked">Blocked</InputGroup.Text>
                        </InputGroup>
                        <FormControl data-cy="blocked-checkbox"
                            type="checkbox"
                            placeholder="Blocked"
                            aria-label="blocked"
                            aria-describedby="blocked" checked={blocked} onChange={handleChangeBlocked}
                        />
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup>
                            <InputGroup.Text id="tags">Tags</InputGroup.Text>
                        </InputGroup>
                        <FormControl
                            placeholder="Tags"
                            aria-label="Tags"
                            aria-describedby="tags"
                            data-cy="tag-text"
                            value={tags}
                            onChange={handleChangeTags}
                        />
                    </InputGroup>

                    <InputGroup className="mb-3">
                        <InputGroup>
                            <InputGroup.Text id="seriesLeft">Serie Count left</InputGroup.Text>
                        </InputGroup>
                        <FormControl
                            data-cy="seriesLeft"
                            type="number"
                            placeholder="seriesLeft"
                            aria-label="seriesLeft"
                            aria-describedby="seriesLeft"
                            value={seriesCount} onChange={handleChangeSeriesCount}
                        />
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup>
                            <InputGroup.Text id="Deadline">Deadline</InputGroup.Text>
                        </InputGroup>
                        <FormControl
                            type="date"
                            placeholder="Deadline"
                            aria-label="Deadline"
                            aria-describedby="Deadline"
                            value={deadline}
                            onChange={handleChangeDeadline}
                        />
                    </InputGroup>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <input type="button" className="btn btn-success" data-cy="add-task-btn" onClick={addTaskToStackFromHere} value="Add task" />
                <button type="button" className="btn btn-secondary" data-cy="close-add-task-btn" onClick={handleShowDialog}>Close</button>
            </Modal.Footer>
        </Modal>
    </Fragment>
        ;
}

export { AddDataModal };