import { React, Fragment, useState } from "react";
import { Form, Button, Card } from "react-bootstrap";
import styled from 'styled-components';

const LoginContainer = styled.div`
    margin: auto;
    width: 50%;
    padding: 10px;
`;
const TokenBox = styled.div`
    padding: 10px;
`;

const CardBox = styled(Card)`
    padding: 10px;
`;


function LoginModal(props) {
    const [token, setToken] = useState("");


    const setLoginData = (event) => {
        props.register(token);
        event.preventDefault();
    }

    const changeToken = (event) => {
        setToken(event.target.value);
      }
      
    return <Fragment>
        <LoginContainer>
        <CardBox>
            <Form onSubmit={setLoginData}>
                <Form.Group controlId="formBasicPassword">
                    <Form.Label>Token</Form.Label>
                    <TokenBox>
                        <Form.Control type="text" placeholder="token" value={token} data-cy="login-token" onChange={changeToken}/>
                    </TokenBox>
                </Form.Group>
                <Button type="submit" variant="primary" data-cy="login-btn">
                    Submit
                </Button>
            </Form>
        </CardBox>
        </LoginContainer>
    </Fragment>;
}

export { LoginModal };