export const recalculateTasks = (tasks, setTimeleft, filter) => {
    let hours = 0;
    let filteredTasks = tasks;
    if (filter !== "") {
      filteredTasks = tasks.filter(t => t.tags.includes(filter));
    }
    for (var task of filteredTasks) {
      if (!task.seriesCount) {
        hours++;
      } else {
        hours += parseInt(task.seriesCount);
      }
    }
    if (hours > 8) {
      setTimeleft(Math.round(hours/8) + "d");
    } else {
      setTimeleft(hours + "h");
    }
  }

export const adjustTask = (tasks, task) => {
    let adjusted = [];
    tasks.forEach(t => {
      if (t.taskId === task.taskId) {
        adjusted.push(task);
      } else {
        adjusted.push(t);
      }
    })
    return adjusted;
}
